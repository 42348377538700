import { Navigate, Route, Routes } from "react-router-dom";
import { LayoutPage } from "../pages/LayoutPage";
import { FormSubmittedPage } from "../pages/FormSubmittedPage/FormSubmittedPage";
import { HomePage } from "../pages/HomePage/HomePage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<LayoutPage />}>
        <Route index element={<HomePage />} />
        <Route path="form_submitted" element={<FormSubmittedPage />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
