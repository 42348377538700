import { Link } from "react-router-dom";

import s from "./header.module.scss";

export const Header = () => {
  return (
    <header className={s.header} id="header">
      <Link className={s.headerTitle} to="/">
        SEREDINSKA
      </Link>
      <address className={s.headerLinksWrapper}>
        <a className={s.headerLink} href="tel:+48538386012">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.167 4.87a2.5 2.5 0 0 0-2.5 2.5v25.26a2.5 2.5 0 0 0 2.5 2.5h11.666a2.5 2.5 0 0 0 2.5-2.5V7.37a2.5 2.5 0 0 0-2.5-2.5h-.139l-.406.974A1.667 1.667 0 0 1 23.75 6.87h-7.5c-.673 0-1.28-.405-1.538-1.026l-.406-.974h-.14Zm-5.834 2.5a5.833 5.833 0 0 1 5.834-5.833h11.666a5.833 5.833 0 0 1 5.834 5.833v25.26a5.833 5.833 0 0 1-5.834 5.834H14.167a5.833 5.833 0 0 1-5.834-5.834V7.37Z"
              fill="#FF5A30"
            />
          </svg>
          <p className={s.headerLinkText}>
            <b>Зателефонуйте нам</b>
            <br />
            +48538386012
          </p>
        </a>
        <a className={s.headerLink} href="mailto:seredinska1978@gmail.com">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M36.54 17.611a12.92 12.92 0 0 1-1.634 4.645c.062.356.094.716.094 1.077 0 1.255-.364 2.456-1.037 3.547-.336.544-.423 1.182-.456 1.604a10.64 10.64 0 0 0 .01 1.52c.038.57.108 1.168.188 1.732-.65-.19-1.355-.38-2.028-.532a14.5 14.5 0 0 0-1.651-.288c-.453-.046-1.107-.082-1.691.107a13.56 13.56 0 0 1-4.168.643c-1.764 0-3.383-.313-4.802-.856-.342.015-.685.023-1.032.023-2.007 0-3.94-.264-5.748-.752C15.214 33.121 19.547 35 24.167 35c1.805 0 3.537-.277 5.133-.786h.016c.04-.002.155-.004.367.018.334.035.764.112 1.26.224.988.222 2.097.551 2.962.825 1.833.583 3.61-1.018 3.282-2.878-.14-.801-.287-1.778-.345-2.625a7.402 7.402 0 0 1-.012-1.032c.009-.106.02-.173.026-.208.938-1.553 1.477-3.322 1.477-5.205 0-2.122-.659-4.06-1.794-5.722Z"
              fill="#FF5A30"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.328 9.497c-2.246 1.718-3.495 3.973-3.495 6.337 0 1.178.307 2.313.883 3.368.219.402.281.817.303 1.097.025.307.012.621-.014.915-.053.59-.18 1.257-.333 1.914a38.732 38.732 0 0 1-.639 2.358c.88-.32 1.843-.647 2.755-.914a17.94 17.94 0 0 1 2.144-.506c.571-.09 1.4-.18 2.113.068 1.6.553 3.391.866 5.288.866 3.579 0 6.757-1.11 9.005-2.83 2.247-1.718 3.495-3.973 3.495-6.337 0-2.363-1.248-4.618-3.495-6.336-2.248-1.72-5.426-2.83-9.005-2.83-3.578 0-6.756 1.11-9.005 2.83ZM7.304 6.85c2.879-2.201 6.784-3.516 11.03-3.516 4.245 0 8.15 1.315 11.03 3.516 2.88 2.203 4.803 5.365 4.803 8.985 0 3.62-1.923 6.78-4.804 8.984-2.88 2.201-6.784 3.515-11.03 3.515-2.238 0-4.376-.364-6.318-1.028-.002.005-.159-.01-.562.053-.464.074-1.057.218-1.731.414-1.343.392-2.85.946-3.999 1.393-1.99.774-4.016-1.136-3.345-3.172.355-1.078.774-2.441 1.047-3.618a11.44 11.44 0 0 0 .26-1.461c.012-.128.015-.222.014-.285A10.293 10.293 0 0 1 2.5 15.833c0-3.62 1.923-6.78 4.804-8.984Zm-3.61 13.69.001.013a.092.092 0 0 1-.002-.014Z"
              fill="#FF5A30"
            />
          </svg>
          <p className={s.headerLinkText}>
            <b>Зв'яжіться з нами</b>
            <br />
            seredinska1978@gmail.com
          </p>
        </a>
      </address>
    </header>
  );
};
