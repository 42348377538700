import s from "./formSubmittedPage.module.scss";

export const FormSubmittedPage = () => {
  return (
    <section className={s.confirmationBody}>
      <div className={s.confirmationBlock}>
        <div className={s.confirmationWrapper}>
          <svg
            className={s.icon}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 330 330"
          >
            <path
              d="m96.333 156.417 51.5 51.5L190.75 165l42.917-42.917M165 319.5A154.502 154.502 0 0 1 55.752 55.752 154.5 154.5 0 1 1 165 319.5Z"
              stroke="#FF5A30"
              strokeWidth="20"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className={s.firstText}>Дякуємо</p>
          <p className={s.secondText}>Форму успішно надіслано</p>
        </div>
      </div>
    </section>
  );
};
