import { useNavigate } from "react-router-dom";
import { sendMessage } from "../../shared/api";

import s from "./form.module.scss";

export const Form = () => {
  const navigate = useNavigate();

  const handleSumbit = async (ev) => {
    ev.preventDefault();
    const {
      name,
      email,
      phone,
      social_media,
      citizenship,
      family,
      animals,
      date_move_in,
      city,
      rooms_number,
      budget,
      meters,
      parking,
      work_info,
      wishes,
      heardAboutUs,
    } = ev.target.elements;

    const formattedDate = date_move_in.value.split("-").reverse().join(".");

    const formData = `
Новий Запит:%0A%0A
Імʼя та призвіще: ${name.value}%0A
Електронна пошта: ${email.value}%0A
Номер телефону: ${phone.value}%0A
Телеграм / Інстаграм (нік): ${social_media.value}%0A
Громадянство: ${citizenship.value}%0A
Склад сімʼї: ${family.value}%0A
Тварини: ${animals.value}%0A
Бажана дата поселення: ${formattedDate}%0A
Mісто: ${city.value}%0A
Кількість кімнат: ${rooms_number.value}%0A
Бюджет (з комунальними): ${budget.value}%0A
Метраж: ${meters.value}%0A
Паркінг: ${parking.value}%0A
Дані про роботу: ${work_info.value}%0A
Окремі побажання: ${wishes.value}%0A
Звідки дізнались: ${heardAboutUs.value}%0A`;

    try {
      await sendMessage(formData);
      navigate("form_submitted");
    } catch (error) {
      alert("Помилка відправки форми");
      console.error(error);
    }
  };

  return (
    <form className={s.form} id="userForm" onSubmit={handleSumbit}>
      <label className={s.formLabel} htmlFor="name">
        Імʼя та призвіще*
      </label>
      <input
        name="name"
        id="surname"
        className={s.formInput}
        type="text"
        required
      />
      <label className={s.formLabel} htmlFor="email">
        Електронна пошта*
      </label>
      <input
        name="email"
        id="email"
        className={s.formInput}
        type="email"
        required
      />
      <label className={s.formLabel} htmlFor="phone">
        Номер телефону*
      </label>
      <input
        name="phone"
        id="phone"
        className={s.formInput}
        type="tel"
        required
      />
      <label className={s.formLabel} htmlFor="social_media">
        Телеграм / Інстаграм (нік)*
      </label>
      <input
        name="social_media"
        id="social_media"
        className={s.formInput}
        type="text"
        required
      />
      <label className={s.formLabel} htmlFor="citizenship">
        Громадянство*
      </label>
      <input
        name="citizenship"
        id="citizenship"
        className={s.formInput}
        type="text"
        required
      />
      <label className={s.formLabel} htmlFor="family">
        Склад сімʼї (якщо є діти - вік)*
      </label>
      <input
        name="family"
        id="family"
        className={s.formInput}
        type="text"
        required
      />
      <label className={s.formLabel} htmlFor="animals">
        Тварини (вік та вага)*
      </label>
      <input
        name="animals"
        id="animals"
        className={s.formInput}
        type="text"
        required
      />
      <label className={s.formLabel} htmlFor="date_move_in">
        Бажана дата поселення*
      </label>
      <input
        name="date_move_in"
        id="date_move_in"
        className={s.formInput}
        type="date"
        required
      />
      <p className={s.formLabel}>Mісто*</p>
      <div className={s.formRadioWrapper}>
        <div className={s.formRadioInputLabelWrapper}>
          <input
            className={s.formRadioInput}
            type="radio"
            id="wroclaw"
            name="city"
            value="Вроцлав"
            required
          />
          <label
            className={`${s.formRadioLabel} ${s.formLabel}`}
            htmlFor="wroclaw"
          >
            Вроцлав
          </label>
        </div>
        <div className={s.formRadioInputLabelWrapper}>
          <input
            className={s.formRadioInput}
            type="radio"
            id="warsaw"
            name="city"
            value="Варшава"
            required
          />
          <label
            className={`${s.formRadioLabel} ${s.formLabel}`}
            htmlFor="warsaw"
          >
            Варшава
          </label>
        </div>
      </div>
      <p className={s.formLabel}>Кількість кімнат*</p>
      <div className={s.formRadioWrapper}>
        <div className={s.formRadioInputLabelWrapper}>
          <input
            className={s.formRadioInput}
            type="radio"
            id="Кавалерка"
            name="rooms_number"
            value="Кавалерка"
            required
          />
          <label
            className={`${s.formRadioLabel} ${s.formLabel}`}
            htmlFor="Кавалерка"
          >
            Кавалерка
          </label>
        </div>
        <div className={s.formRadioInputLabelWrapper}>
          <input
            className={s.formRadioInput}
            type="radio"
            id="Двокімнатна"
            name="rooms_number"
            value="Двокімнатна"
            required
          />
          <label
            className={`${s.formRadioLabel} ${s.formLabel}`}
            htmlFor="Двокімнатна"
          >
            Двокімнатна
          </label>
        </div>
        <div className={s.formRadioInputLabelWrapper}>
          <input
            className={s.formRadioInput}
            type="radio"
            id="Трикімнатна"
            name="rooms_number"
            value="Трикімнатна"
            required
          />
          <label
            className={`${s.formRadioLabel} ${s.formLabel}`}
            htmlFor="Трикімнатна"
          >
            Трикімнатна
          </label>
        </div>
        <div className={s.formRadioInputLabelWrapper}>
          <input
            className={s.formRadioInput}
            type="radio"
            id="Будинок"
            name="rooms_number"
            value="Будинок"
            required
          />
          <label
            className={`${s.formRadioLabel} ${s.formLabel}`}
            htmlFor="Будинок"
          >
            Будинок
          </label>
        </div>
        <div className={s.formRadioInputLabelWrapper}>
          <input
            className={s.formRadioInput}
            type="radio"
            id="Кімната в квартирі або будинку"
            name="rooms_number"
            value="Кімната в квартирі або будинку"
            required
          />
          <label
            className={`${s.formRadioLabel} ${s.formLabel}`}
            htmlFor="Кімната в квартирі або будинку"
          >
            Кімната в квартирі або будинку
          </label>
        </div>
      </div>
      <label className={s.formLabel} htmlFor="budget">
        Бюджет (з комунальними)*
      </label>
      <input
        name="budget"
        id="budget"
        className={s.formInput}
        type="text"
        required
      />
      <label className={s.formLabel} htmlFor="apartment_area">
        Метраж*
      </label>
      <input
        name="meters"
        id="apartment_area"
        className={s.formInput}
        type="text"
        required
      />
      <p className={s.formLabel}>Паркінг*</p>
      <div className={s.formRadioWrapper}>
        <div className={s.formRadioInputLabelWrapper}>
          <input
            className={s.formRadioInput}
            type="radio"
            id="Yes"
            name="parking"
            value="Так"
            required
          />
          <label className={`${s.formRadioLabel} ${s.formLabel}`} htmlFor="Yes">
            Так
          </label>
        </div>
        <div className={s.formRadioInputLabelWrapper}>
          <input
            className={s.formRadioInput}
            type="radio"
            id="No"
            name="parking"
            value="Ні"
            required
          />
          <label className={`${s.formRadioLabel} ${s.formLabel}`} htmlFor="No">
            Ні
          </label>
        </div>
      </div>
      <label className={s.formLabel} htmlFor="work_info">
        Дані про роботу*
      </label>
      <input
        name="work_info"
        id="work_info"
        className={s.formInput}
        type="text"
        required
      />
      <label className={s.formLabel} htmlFor="wishes">
        Окремі побажання*
      </label>
      <input
        name="wishes"
        id="wishes"
        className={s.formInput}
        type="text"
        required
      />
      <label className={s.formLabel} htmlFor="heardAboutUs">
        Звідки Ви про мене дізнались*
      </label>
      <input
        name="heardAboutUs"
        id="heardAboutUs"
        className={s.formInput}
        type="text"
        required
      />
    </form>
  );
};
