import { Link } from "react-router-dom";

import s from "./footer.module.scss";

export const Footer = () => {
  return (
    <footer className={s.footer}>
      <div className={s.footerLogoSvgs}>
        <Link className={s.footerLogo} to="/">
          SEREDINSKA
        </Link>
        <address className={s.linksList}>
          <a href="https://wa.me/+48538386012">
            <svg xmlns="http://www.w3.org/2000/svg">
              <path d="M22 11.742c0 5.381-4.395 9.743-9.818 9.743a9.835 9.835 0 0 1-4.746-1.212L2 22l1.772-5.227a9.64 9.64 0 0 1-1.409-5.03C2.363 6.361 6.76 2 12.182 2 17.605 2 22 6.362 22 11.742Zm-9.818-8.19c-4.552 0-8.255 3.674-8.255 8.19 0 1.793.584 3.452 1.573 4.803l-1.032 3.042 3.172-1.009a8.25 8.25 0 0 0 4.542 1.355c4.551 0 8.255-3.674 8.255-8.19 0-4.517-3.703-8.191-8.255-8.191Zm4.958 10.434c-.06-.1-.221-.16-.462-.278-.24-.12-1.424-.698-1.644-.777-.22-.08-.382-.12-.542.12-.16.238-.621.776-.762.935-.14.16-.28.18-.522.06-.24-.119-1.015-.371-1.935-1.185-.715-.633-1.198-1.414-1.339-1.653-.14-.24-.014-.368.106-.487.108-.107.24-.279.361-.418.12-.14.16-.24.24-.399.081-.16.041-.298-.02-.418-.06-.12-.541-1.295-.742-1.773-.2-.478-.4-.398-.541-.398-.14 0-.3-.02-.461-.02-.16 0-.422.06-.642.299-.22.239-.842.816-.842 1.991 0 1.175.862 2.31.982 2.47.12.159 1.665 2.649 4.111 3.605 2.447.956 2.447.637 2.889.597.44-.04 1.423-.578 1.625-1.135.2-.558.2-1.036.14-1.136Z" />
            </svg>
          </a>
          <a href="https://t.me/Seredinska_Olena">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
              <path d="m18.578 20.422 2.297-10.828c.203-.953-.344-1.328-.969-1.094l-13.5 5.203c-.922.359-.906.875-.156 1.109l3.453 1.078 8.016-5.047c.375-.25.719-.109.438.141l-6.484 5.859-.25 3.563c.359 0 .516-.156.703-.344l1.687-1.625 3.5 2.578c.641.359 1.094.172 1.266-.594zM28 14c0 7.734-6.266 14-14 14S0 21.734 0 14 6.266 0 14 0s14 6.266 14 14z" />
            </svg>
          </a>
          <a href="https://www.facebook.com/profile.php?id=100003044063934">
            <svg xmlns="http://www.w3.org/2000/svg">
              <path d="M17.563 1.005 14.786 1c-3.12 0-5.137 2.125-5.137 5.414v2.497H6.857c-.242 0-.437.2-.437.448v3.617c0 .248.196.449.437.449h2.792v9.126c0 .248.196.449.437.449h3.643a.443.443 0 0 0 .437-.449v-9.126h3.265c.241 0 .436-.2.436-.449l.002-3.617a.455.455 0 0 0-.128-.317.43.43 0 0 0-.31-.131h-3.265V6.794c0-1.017.236-1.533 1.526-1.533h1.87c.241 0 .437-.202.437-.45V1.454a.443.443 0 0 0-.436-.448Z" />
            </svg>
          </a>
          <a href="https://instagram.com/seredinska.realtor.wroclaw?igshid=MWZjMTM2ODFkZg==">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 32 32"
            >
              <path d="M16 2.881c4.275 0 4.781.019 6.462.094 1.563.069 2.406.331 2.969.55a4.952 4.952 0 0 1 1.837 1.194 5.015 5.015 0 0 1 1.2 1.838c.219.563.481 1.412.55 2.969.075 1.688.094 2.194.094 6.463s-.019 4.781-.094 6.463c-.069 1.563-.331 2.406-.55 2.969a4.94 4.94 0 0 1-1.194 1.837 5.02 5.02 0 0 1-1.837 1.2c-.563.219-1.413.481-2.969.55-1.688.075-2.194.094-6.463.094s-4.781-.019-6.463-.094c-1.563-.069-2.406-.331-2.969-.55a4.952 4.952 0 0 1-1.838-1.194 5.02 5.02 0 0 1-1.2-1.837c-.219-.563-.481-1.413-.55-2.969-.075-1.688-.094-2.194-.094-6.463s.019-4.781.094-6.463c.069-1.563.331-2.406.55-2.969a4.964 4.964 0 0 1 1.194-1.838 5.015 5.015 0 0 1 1.838-1.2c.563-.219 1.412-.481 2.969-.55 1.681-.075 2.188-.094 6.463-.094zM16 0c-4.344 0-4.887.019-6.594.094-1.7.075-2.869.35-3.881.744-1.056.412-1.95.956-2.837 1.85a7.833 7.833 0 0 0-1.85 2.831C.444 6.538.169 7.7.094 9.4.019 11.113 0 11.656 0 16s.019 4.887.094 6.594c.075 1.7.35 2.869.744 3.881.413 1.056.956 1.95 1.85 2.837a7.82 7.82 0 0 0 2.831 1.844c1.019.394 2.181.669 3.881.744 1.706.075 2.25.094 6.594.094s4.888-.019 6.594-.094c1.7-.075 2.869-.35 3.881-.744 1.05-.406 1.944-.956 2.831-1.844s1.438-1.781 1.844-2.831c.394-1.019.669-2.181.744-3.881.075-1.706.094-2.25.094-6.594s-.019-4.887-.094-6.594c-.075-1.7-.35-2.869-.744-3.881a7.506 7.506 0 0 0-1.831-2.844A7.82 7.82 0 0 0 26.482.843C25.463.449 24.301.174 22.601.099c-1.712-.081-2.256-.1-6.6-.1z" />
              <path d="M16 7.781c-4.537 0-8.219 3.681-8.219 8.219s3.681 8.219 8.219 8.219 8.219-3.681 8.219-8.219A8.221 8.221 0 0 0 16 7.781zm0 13.55a5.331 5.331 0 1 1 0-10.663 5.331 5.331 0 0 1 0 10.663zM26.462 7.456a1.919 1.919 0 1 1-3.838 0 1.919 1.919 0 0 1 3.838 0z" />
            </svg>
          </a>
          <a href="https://youtube.com/@user-zs2ky7on8c?si=5t0718sUodiqH8il">
            <svg xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.39 4.114a3.007 3.007 0 0 1 2.116 2.115c.514 1.878.494 5.793.494 5.793s0 3.894-.494 5.772a3.007 3.007 0 0 1-2.116 2.116c-1.878.494-9.39.494-9.39.494s-7.493 0-9.39-.514a3.008 3.008 0 0 1-2.116-2.116C0 15.916 0 12.002 0 12.002s0-3.895.494-5.773A3.068 3.068 0 0 1 2.61 4.094C4.488 3.6 12 3.6 12 3.6s7.513 0 9.391.514Zm-5.535 7.888L9.608 15.6V8.404l6.247 3.598Z"
              />
            </svg>
          </a>
        </address>
      </div>
      <a className={s.footerGoUp} href="#header">
        <svg xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.293 15.707a1 1 0 0 1 0-1.414l7-7a1 1 0 0 1 1.414 0l7 7a1 1 0 0 1-1.414 1.414L12 9.414l-6.293 6.293a1 1 0 0 1-1.414 0Z"
          />
        </svg>
      </a>
    </footer>
  );
};
