import axios from "axios";

const { REACT_APP_TELEGRAM_BOT_TOKEN, REACT_APP_CHAT_ID } = process.env;

export const sendMessage = async (formData) => {
  try {
    await axios.post(
      `https://api.telegram.org/bot${REACT_APP_TELEGRAM_BOT_TOKEN}/sendMessage?chat_id=${REACT_APP_CHAT_ID}&text=${formData}`
    );
  } catch (error) {
    throw error;
  }
};
